import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import { VuePdf } from 'vue3-pdfjs'
import mitt from 'mitt';

const emitter = mitt();

const app = createApp(App).use(store).use(VuePdf)

app.config.globalProperties.emitter = emitter;

app.mount('#app')