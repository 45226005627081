<template>
  <div class="app-wrapper">
    <div id="ruler"></div>
    <div class="top-bar">
      <div class="menu-left-section">
        <a href="/" class="logo">
        </a>
      </div>
      <div class="menu-center-section menu-hide-mobile">
        <input @change="handlePdfChange" type="file" id="pdf" name="pdf" accept="application/pdf"
          style="display: none;" />
        <div>
          <label class="upload-link" @click="toggleUploadDiv()" :class="{ 'active-link': showUploadDiv }">Upload PDF</label>
        </div>
        <div>
          <button class="draw-link" @click="toggleDrawing()" :class="{ 'active-link active-link-pointer': drawState }">Draw Polygon</button>
        </div>
        <label class="get-result" @click="showResultsTable()" :class="{ 'active-link active-link-pointer': showResults }">Get Results</label>
      </div>
      <div class="menu-right-section">
        <input type="checkbox" id="menu-toggle" class="menu-toggle" />
        <label for="menu-toggle" class="hamburger-menu">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </label>
        <div class="menu-side-menu">


          <div class="test-side-menu menu-show-mobile">
          <input @change="handlePdfChange" type="file" id="pdf" name="pdf" accept="application/pdf"
          style="display: none;" />
        <div>
          <label class="upload-link" @click="toggleUploadDiv()" :class="{ 'active-link': showUploadDiv }">Upload PDF</label>
        </div>
        <div>
          <button class="draw-link" @click="toggleDrawing()" :class="{ 'active-link active-link-pointer': drawState }">Draw Polygon</button>
        </div>
        <label class="get-result" @click="showResultsTable()" :class="{ 'active-link active-link-pointer': showResults }">Get Results</label>
      </div>



          <a href="https://www.geoplastglobal.com/en/contacts/" class="menu-link" target="_blank">Contact Us</a>
          <a href="https://www.geoplastglobal.com/en/products/water/aquabox/" class="menu-link" target="_blank">Aquabox</a>
          <a href="https://www.geoplastglobal.com/en/products/water/aquabox/calculator/" class="menu-link" target="_blank">Aquabox Calculator</a>
          
          <a href="https://www.geoplastglobal.com/en/" class="menu-link side-menu-sites-links">GEOPLASTGLOBAL.COM</a>
          <a href="https://portal.geoplastglobal.com/en/login" class="menu-link">PORTAL.GEOPLASTGLOBAL.COM</a>

          <div class="social-wraper">
            <a href="https://www.facebook.com/geoplast" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
            <a href="https://x.com/geoplast" target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
            <a href="https://www.youtube.com/user/GEOPLASTspa" target="_blank"><i class="fa-brands fa-youtube"></i></a>
            <a href="https://www.linkedin.com/company/geoplast-spa" target="_blank"><i
                class="fa-brands fa-linkedin-in"></i></a>
            <a href="https://www.instagram.com/geoplastglobal/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
            <a class="oi_icon" href="https://openinnovation.me/" target="_blank">
              <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.49 44.16">
                <path class="cls-1"
                  d="M277.25,426c0-9.58,5.77-16.5,15.84-16.5s15.83,6.92,15.83,16.5-5.76,16.56-15.83,16.56S277.25,435.67,277.25,426ZM302,426c0-5.4-2.73-11-8.91-11s-8.92,5.64-8.92,11,2.73,11.1,8.92,11.1S302,431.48,302,426Z"
                  transform="translate(-277.25 -398.42)" />
                <path class="cls-1" d="M311.83,398.42h6.92V405h-6.92Zm0,11.95h6.92v31.36h-6.92Z"
                  transform="translate(-277.25 -398.42)" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <drawing-canvas @loadedPdf="drawState = true; this.targetPage=null" @closePdf="e => pdfSource = ''"
      :aquaboxScale="this.aquaboxScale" :counters="this.counters" :pdfFile="pdf" :targetPage="targetPage"
      :drawState="drawState" @caluclations="calc => handleCalculations(calc)" @clearCalculations="this.results = null" />

    <pdf-picker :pdfSrc="pdfSource" @selectPage="number => targetPage = number" />

    <div class="scale">
      <div class="units-option">
        <p>Choose your units: &nbsp;&nbsp;</p>
        <input type="checkbox" class="metric-checkbox custom-checkbox" v-model="isMetric">
        <label for="metric-checkbox">Metric</label>
        <input type="checkbox" class="imperial-checkbox" v-model="isImperial" disabled>
        <label for="imperial-checkbox" class="imperial-text"><p>Imperial</p></label>
      </div>
      <div class="scale-input">
        <p>SCALE: 1cm / &nbsp;</p>
        <input v-model="scale" type="text">
        <p>&nbsp; m &nbsp;</p>
        <button class="scale-button" @click="calculateScale()"><span>Set scale</span></button>
      </div>
    </div>

    <div class="upload-div" :class="{ 'hidden': !showUploadDiv }">
      <div class="upload-content">
        <p>Please upload PDF Document</p>
        <div class="upload-button">
          <button @click="openFileUploadDialog"><span>Choose File</span></button>
        </div>
      </div>
    </div>

    <!-- Table.vue component -->
    <Table @dropdownChange="data => refreshCalculations(data)" :results="results" :displayTable="showResults" />
      <!-- <PdfExport/> -->
  </div>
</template>

<script>

import DrawingCanvas from './components/DrawingCanvas.vue'
import PdfPicker from './components/PdfPicker.vue'
import Table from './components/Table.vue'
import PdfExport from './components/PdfExport.vue'
import Calculator from './assets/lib/calculator'

export default {
  components: { DrawingCanvas, PdfPicker, Table, PdfExport },
  name: 'App',
  data() {
    return {
      pdfSource: '',
      counters: {
        aquabox: {
          A0: 0,
          A1: 0,
          A2: 0,
          A3: 0,
          A4: 0
        },
        cube: {
          C0: 0,
          C1: 0,
          C2: 0,
          C3: 0,
          C4: 0
        }
      },
      pdf: null,
      targetPage: null,
      drawState: false,
      scale: null,
      hasPolygon: false,
      aquaboxScale: 1,
      showUploadDiv: true,
      showResults: false,
      isMetric: true,
      isImperial: false,
      displayUpload: true,
      displayTable: false,
      results: null,
      dropdowns: null
    }
  },
  methods: {
    handlePdfChange(e) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        this.pdf = selectedFile;
        const url = URL.createObjectURL(selectedFile);
        this.pdfSource = url;
        this.toggleDrawing();
      } else {
        // Handle the case where no file is selected or the user cancels the upload.
        console.error('No file selected or upload canceled.');
      }
    },
    handleCalculations(calc){
      this.results = calc;
    },
    showResultsTable() {
      this.displayUpload= false;
      this.drawState = false;


      if (!this.showResults) {
        // Only toggle the showResults variable when you want to show the table
        this.showResults = true;
      }

      // Set visibility of the table
      this.displayUpload= true;

      // Hide the upload div
      this.showUploadDiv = false;
    },
    toggleDrawing(){

      this.showResults = false;
      this.showUploadDiv = false;
      if(this.pdfSource == '')
        this.drawState = true;
    },
    calculateScale() {

      var addClass = document.querySelector('.scale-loader');

    // Add a class to the button
    addClass.classList.add('open');

    

      let cm = document.getElementById('ruler').getClientRects()[0].width;
      cm /= 100;
      cm *= 0.75;
      cm /= this.scale;
      cm *= 100;

      let pixel = cm * 75;

      let initialScale = 50;
      let scaled = pixel;

      let calculatedScale = scaled / initialScale;

      this.resetCounters();

      this.aquaboxScale = calculatedScale;

      if (this.dropdowns) {
        this.refreshCalculations(this.dropdowns);
      } else {
        this.refreshCalculations();
      }
      
      alert(`The scale is now set to 1cm/${this.scale}m. \nPlease be patient while the changes load.`);

    },

    resetCounters(){
      this.counters = {
        aquabox: {
          A0: 0,
          A1: 0,
          A2: 0,
          A3: 0,
          A4: 0
        },
        cube: {
          C0: 0,
          C1: 0,
          C2: 0,
          C3: 0,
          C4: 0
        }
      }
    },

    refreshCalculations(data=null){
      console.log('updated dropdowns')
      this.dropdowns = data;
      if (data){
        this.results = new Calculator(this.counters, data).calculateAll();
      } else {
        this.results = new Calculator(this.counters).calculateAll();
      }
    },

    toggleUploadDiv() {
      this.drawState = false;
      this.showResults = false;

      this.displayUpload=true;
      
      this.showUploadDiv = !this.showUploadDiv;

      this.displayTable=false

    },
    openFileUploadDialog() {
      const fileInput = this.$el.querySelector('#pdf');
      if (fileInput) {
        fileInput.click(); // Trigger the file input click event
      }
      // alert('Drawing scale set to 1cm/'+this.scale+'m');

    }
    
  },
  computed:{
    drawText(){
      return this.drawState ? 'Drawing...' : 'Draw polygon'
    }
  }
}

// Open and close menu
document.addEventListener('DOMContentLoaded', function () {
  const menuToggle = document.querySelector('.menu-toggle');
  const sideMenu = document.querySelector('.menu-side-menu');
  const hamburgerMenu = document.querySelector('.hamburger-menu');

  menuToggle.addEventListener('change', function () {
    if (menuToggle.checked) {
      // Show the menu
      sideMenu.style.top = '0';
      sideMenu.style.visibility = 'visible';
      sideMenu.style.opacity = '1';
      sideMenu.style.transition = 'top 0.3s, visibility 0s, opacity 0.3s';

      // Reset the transition property
      setTimeout(() => {
        sideMenu.style.transition = 'none';
      }, 300); // After the transition duration (0.3s in this case)
    } else {
      // Hide the menu with a reverse transition
      sideMenu.style.top = '-100%';
      sideMenu.style.visibility = 'hidden';

      // Add the reverse transition
      sideMenu.style.transition = 'top 0.3s, visibility 0.3s';

      // Reset the transition property after a delay
      setTimeout(() => {
        sideMenu.style.transition = 'none';
      }, 300); // After the reverse transition duration (0.3s in this case)
    }
  });

  // Close the side menu when clicking outside
  document.addEventListener('click', function (event) {
    if (
      event.target !== sideMenu &&
      event.target !== hamburgerMenu &&
      event.target !== menuToggle &&
      !hamburgerMenu.contains(event.target)
    ) {
      menuToggle.checked = false;
      // Hide the menu with the reverse transition
      sideMenu.style.top = '-100%';
      sideMenu.style.visibility = 'hidden';
      sideMenu.style.transition = 'top 0.3s, visibility 0.3s';

      // Reset the transition property after a delay
      setTimeout(() => {
        sideMenu.style.transition = 'none';
      }, 300); // After the reverse transition duration (0.3s in this case)
    }
  });
});

</script>

<style lang="scss">
@import './assets/styles/main.scss';
</style>