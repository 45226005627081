export default class Calculator {
    constructor(quantities, dropdowns = {AquaboxST:'Pedestrian',Levels:1, TypeOfTank:'Dispersing'}){
        this.aquabox = quantities.aquabox;
        this.cube = quantities.cube;

        this.dropdownAquaboxST = dropdowns.AquaboxST;
        this.dropdownLevels = dropdowns.Levels;
        this.dropdownTypeOfTank = dropdowns.TypeOfTank;
    }

    calculateAll(){
        let aquabox = this.aquabox;
        let cube= this.cube;

        let layout = {
            "1":{
                "aquabox":1,
                "aquaboxCube":0
            },
            "1.5":{
                "aquabox":1,
                "aquaboxCube":1
            },
            "2":{
                "aquabox":2,
                "aquaboxCube":0
            },
            "2.5":{
                "aquabox":2,
                "aquaboxCube":1
            },
            "3":{
                "aquabox":3,
                "aquaboxCube":0
            },
        }

        let loadType = {
            "Pedestrian":{
                "aquabox": "Aquabox ST",
                "aquaboxCube": "Aquabox Cube ST"
            },
            "Pedestrian and Cars":{
                "aquabox": "Aquabox STR",
                "aquaboxCube": "Aquabox Cube STR"
            },
            "SLW30 (light trucks)":{
                "aquabox": "Aquabox STR",
                "aquaboxCube": "Aquabox Cube STR"
            },
            "SLW60 (heavy trucks)":{
                "aquabox": "Aquabox HP",
                "aquaboxCube": "Aquabox Cube HP"
            },
        }

        // Dropdown menu

        let dropdownAquaboxST = this.dropdownAquaboxST;
        let dropdownLevels = this.dropdownLevels;
        let loadTypeAquabox = loadType[dropdownAquaboxST]['aquabox'];
        let loadTypeCube = loadType[dropdownAquaboxST]['aquaboxCube'];
        let dropdownAquaboxLevels = layout[dropdownLevels]['aquabox'];
        let dropdownCubeLevels = layout[dropdownLevels]['aquaboxCube'];
        let dropdownTypeOfTank = this.dropdownTypeOfTank;

        // Calculations - Aquabox

        let a0;
        let a1;
        let a2;
        let a3;
        let a4;
        if (dropdownLevels == 0) {
            a0 = 0;
            a1 = 0;
            a2 = 0;
            a3 = 0;
            a4 = 0;
        } else {
            a0 = aquabox.A0 * dropdownAquaboxLevels;
            a1 = aquabox.A1 * dropdownAquaboxLevels;
            a2 = aquabox.A2 * dropdownAquaboxLevels;
            a3 = aquabox.A3 * dropdownAquaboxLevels;
            a4 = aquabox.A4 * dropdownAquaboxLevels;
        }

        let c0Inspection = cube.C0 * dropdownAquaboxLevels * 2;
        let c1Inspection = cube.C1 * dropdownAquaboxLevels * 2;
        let c2Inspection = cube.C2 * dropdownAquaboxLevels * 2;
        let c3Inspection = cube.C3 * dropdownAquaboxLevels * 2;
        let c4Inspection = cube.C4 * dropdownAquaboxLevels * 2;

        let c0;
        let c1;
        let c2;
        let c3;
        let c4;
        if (dropdownCubeLevels == 1 && dropdownAquaboxLevels >= 0) {
            c0 = aquabox.A0 + cube.C0;
            c1 = aquabox.A1 + cube.C1;
            c2 = aquabox.A2 + cube.C2;
            c3 = aquabox.A3 + cube.C3;
            c4 = aquabox.A4 + cube.C4;
        } else {
            c0 = 0;
            c1 = 0;
            c2 = 0;
            c3 = 0;
            c4 = 0;
        }

        // Calculations - Bill of Quantities

        let aquaboxST = (a0 + a1 + a2 + a3 + a4) * 2;

        let aquaboxSidewallGrids = a1 * 1 + a2 * 2 + a3 * 3 + a4 * 4;
        
        let caps;
        if (dropdownAquaboxLevels != 0) {
            caps = ((a0 + a1 + a2 + a3 + a4) / dropdownAquaboxLevels + ((c0Inspection + c1Inspection + c2Inspection + c3Inspection + c4Inspection)/( 2 * dropdownAquaboxLevels)))*4;
        } else {
            caps = (c0 + c1 + c2 + c3 + c4) * 4;
        }

        let aquaboxCubeST = (c0 + c1 + c2 + c3 + c4 + c0Inspection + c1Inspection + c2Inspection + c3Inspection + c4Inspection) * 2;
    
        let cubeSidewallGrids = c1 * 1 + c2 * 2 + c3 * 3 + c4 * 4 + c1Inspection * 1 + c2Inspection * 2 + c3Inspection * 3 + c4Inspection * 4;

        let d400Caps;
        if (dropdownLevels != 0.5) {
            d400Caps = (c0Inspection + c1Inspection + c2Inspection + c3Inspection + c4Inspection) / (2 * dropdownAquaboxLevels);
        } else {
            d400Caps = cube.C0 + cube.C1 + cube.C2 + cube.C3 + cube.C4;
        }

        let doubleJoints;
        if (dropdownLevels == 1 || dropdownLevels == 0.5) {
            doubleJoints = 0;
        } else {
            doubleJoints = (((a0 + c0 + c0Inspection / 2) * 4 + (a1 + c1 + c1Inspection / 2) * 3 + (a2 + c2 + c2Inspection / 2) * 2 + (a3 + c3 + c3Inspection / 2 ) *1)*(Math.ceil(dropdownLevels) - 1)) / ( 2 * (Math.ceil(dropdownLevels)))+( c0Inspection * 4 + c1Inspection * 3 + c2Inspection * 2 + c3Inspection * 1) / 2 
        }

        let singleJoints;
        if (dropdownLevels == 0.5) {
            singleJoints = (c0 * 4 + c1 * 3 + c2 * 2 + c3 * 1) / 2 * 2;
        } else {
            singleJoints = ( (a0 + c0Inspection / 2) * 4 + (a1 + c1Inspection / 2) * 3 + (a2 + c2Inspection / 2) * 2 + (a3 + c3Inspection / 2) * 1) / (2 * (dropdownAquaboxLevels)) * 2;
        }

        let d4Connector;
        if (dropdownCubeLevels == 0) {
            d4Connector = (c0Inspection + c1Inspection + c2Inspection + c3Inspection + c4Inspection) / (2 * dropdownAquaboxLevels);
        } else {
            d4Connector = c0 + c1 + c2 + c3 + c4;
        }

        let oRing = d400Caps;

        let currugatedPipe400 = d400Caps;

        // Calculations - Structure Dimensions

        let tankHeight = dropdownLevels * 0.8;

        let tankSurface = caps / 4 * 0.75 * 0.75;

        let grossVolume = tankHeight * tankSurface;

        let netStorageVolume = grossVolume * 0.96;

        let tankHeightFt = tankHeight / 0.3048;
        let tankSurfaceSft = tankSurface * 10.76391041671;
        let grossVolumeCft = tankHeightFt * tankSurfaceSft;
        let netStorageVolumeCft = grossVolumeCft * 0.96;

        // Calculations - Bill of Quantities

        let impermeableLiner;
        if (dropdownTypeOfTank == "Dispersing") {
            impermeableLiner = 0;
        } else {
            impermeableLiner = ((aquabox.A1 + aquabox.A2 + aquabox.A3 + aquabox.A4 + cube.C1 + cube.C2 + cube.C3 + cube.C4) * 0.75 * tankHeight + 2 * tankSurface) * 1.2;
        }
        
        let geotextile;
        if (dropdownTypeOfTank == "Dispersing") {
            geotextile = ((aquabox.A1 + aquabox.A2 + aquabox.A3 + aquabox.A4 + cube.C1 + cube.C2 + cube.C3 + cube.C4) * 0.75 * tankHeight + 2 * tankSurface) * 1.2;
        } else {
            geotextile = impermeableLiner * 2;
        }

        let impermeableLinerSft = impermeableLiner * 10.76391041671;
        let geotextileSft = geotextile * 10.76391041671;

        // Calculations - Covering of clear stones

        let lateralThicknessOfStone = 0.15;
        let topThicknessOfStone = 0.3;
        let bottomThicknessOfStone = 0.25;

        let volumeOfStone;
        if (dropdownAquaboxLevels >= 1) {
            volumeOfStone = (aquaboxSidewallGrids * 0.75 * (0.8 + topThicknessOfStone + bottomThicknessOfStone) + cubeSidewallGrids * 0.75 * (0.4)) * lateralThicknessOfStone + tankSurface * topThicknessOfStone + tankSurface * bottomThicknessOfStone + lateralThicknessOfStone * lateralThicknessOfStone * a2 * (tankHeight + topThicknessOfStone + bottomThicknessOfStone);
        } else {
            volumeOfStone = (aquaboxSidewallGrids * 0.75 * (0.8) + cubeSidewallGrids * 0.75 * (0.4 + bottomThicknessOfStone + topThicknessOfStone)) * lateralThicknessOfStone + tankSurface * topThicknessOfStone + tankSurface * bottomThicknessOfStone + lateralThicknessOfStone * lateralThicknessOfStone * c2 * (tankHeight + topThicknessOfStone + bottomThicknessOfStone);
        }

        let netStorageVolumeStone = volumeOfStone * 0.4;

        let impermeableLinerStone;
        if (dropdownTypeOfTank == "Dispersing") {
            impermeableLinerStone = 0;
        } else {
            impermeableLinerStone = ((aquaboxSidewallGrids * 0.75 * (0.8 + topThicknessOfStone) + cubeSidewallGrids * 0.75 * (0.4 + bottomThicknessOfStone)) + tankSurface * 2) * 1.3;
        }

        let geotextileStone;
        if (dropdownTypeOfTank == "Dispersing") {
            geotextileStone = ((aquaboxSidewallGrids * 0.75 * (0.8 + topThicknessOfStone) + cubeSidewallGrids * 0.75 * (0.4 + bottomThicknessOfStone)) + tankSurface *2) * 1.3;
        } else {
            geotextileStone = ((aquaboxSidewallGrids * 0.75 * (0.8 + topThicknessOfStone) + cubeSidewallGrids * 0.75 * (0.4 + bottomThicknessOfStone)) + tankSurface *2) * 2 * 1.3;
        }

        let lateralThicknessOfStoneFt = lateralThicknessOfStone / 0.3048;
        let topThicknessOfStoneFt = topThicknessOfStone / 0.3048;
        let bottomThicknessOfStoneFt = bottomThicknessOfStone / 0.3048;
        let volumeOfStoneCft = volumeOfStone * 35.3147;
        let netStorageVolumeStoneCft = netStorageVolumeStone * 35.3147;
        let impermeableLinerStoneSft = impermeableLinerStone * 10.76391041671;
        let geotextileStoneSft = geotextileStone * 10.76391041671;

        // Calculations - D4 additional (Bottom level)

        let D4Additional;
        if (dropdownLevels == 1.5) {
            D4Additional = c0 + c1 + c2 + c3 + c4 - (c0Inspection + c1Inspection + c2Inspection + c3Inspection) / 2;
        } else {
            D4Additional = c0 + c1 + c2 + c3 + c4 - (c0Inspection + c1Inspection + c2Inspection + c3Inspection) / 2 - d400Caps;
        }

        // return Aquabox Calculation Results
        
        let results = {
            aquaboxST : aquaboxST,
            aquaboxSidewallGrids : aquaboxSidewallGrids,
            caps : caps,
            aquaboxCubeST : aquaboxCubeST,
            cubeSidewallGrids : cubeSidewallGrids,
            d400Caps : d400Caps,
            doubleJoints : doubleJoints,
            singleJoints : singleJoints,
            d4Connector : d4Connector,
            oRing : oRing,
            currugatedPipe400 : currugatedPipe400,
            impermeableLiner : parseFloat(impermeableLiner.toFixed(2)),
            geotextile : parseFloat(geotextile.toFixed(2)),
            impermeableLinerSft : parseFloat(impermeableLinerSft.toFixed(2)),
            geotextileSft : parseFloat(geotextileSft.toFixed(2)),
            a0 : a0,
            a1 : a1,
            a2 : a2,
            a3 : a3,
            a4 : a4,
            c0Inspection : c0Inspection,
            c1Inspection : c1Inspection,
            c2Inspection : c2Inspection,
            c3Inspection : c3Inspection,
            c4Inspection : c4Inspection,
            c0 : c0,
            c1 : c1,
            c2 : c2,
            c3 : c3,
            c4 : c4,
            tankHeight : parseFloat(tankHeight.toFixed(2)),
            tankSurface : parseFloat(tankSurface.toFixed(2)),
            grossVolume : parseFloat(grossVolume.toFixed(2)),
            netStorageVolume : parseFloat(netStorageVolume.toFixed(2)),
            tankHeightFt : parseFloat(tankHeightFt.toFixed(2)),
            tankSurfaceSft : parseFloat(tankSurfaceSft.toFixed(2)),
            grossVolumeCft : parseFloat(grossVolumeCft.toFixed(2)),
            netStorageVolumeCft : parseFloat(netStorageVolumeCft.toFixed(2)),
            lateralThicknessOfStone : parseFloat(lateralThicknessOfStone.toFixed(2)),
            topThicknessOfStone : parseFloat(topThicknessOfStone.toFixed(2)),
            bottomThicknessOfStone : parseFloat(bottomThicknessOfStone.toFixed(2)),
            volumeOfStone : parseFloat(volumeOfStone.toFixed(2)),
            netStorageVolumeStone : parseFloat(netStorageVolumeStone.toFixed(2)),
            impermeableLinerStone : parseFloat(impermeableLinerStone.toFixed(2)),
            geotextileStone : parseFloat(geotextileStone.toFixed(2)),
            lateralThicknessOfStoneFt : parseFloat(lateralThicknessOfStoneFt.toFixed(2)),
            topThicknessOfStoneFt : parseFloat(topThicknessOfStoneFt.toFixed(2)),
            bottomThicknessOfStoneFt : parseFloat(bottomThicknessOfStoneFt.toFixed(2)),
            volumeOfStoneCft : parseFloat(volumeOfStoneCft.toFixed(2)),
            netStorageVolumeStoneCft : parseFloat(netStorageVolumeStoneCft.toFixed(2)),
            impermeableLinerStoneSft : parseFloat(impermeableLinerStoneSft.toFixed(2)),
            geotextileStoneSft : parseFloat(geotextileStoneSft.toFixed(2)),
            D4Additional : D4Additional, 
            dropdownAquaboxLevels : dropdownAquaboxLevels,
            dropdownCubeLevels : dropdownCubeLevels,
            loadTypeAquabox : loadTypeAquabox,
            loadTypeCube : loadTypeCube,
        }

        return results;
    }
}