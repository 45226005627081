<template>
  <div class="drawing-canvas">
   <button class="default-button toggle-draw-button" @click="isDrawing = true;" :class="{'hidden': !drawState,'active': isDrawing}"><span>Draw</span></button>
   <button class="default-button toggle-draw-button" @click="resetPolygon();" :class="{'hidden': !group}"><span>Reset</span></button>
    <canvas
      ref="can"
      id="canvas-tools"
      class="canvas"
      
    ><canvas id="bg"></canvas></canvas>
  </div>
  <img style="display:none" id="aquabox" src="../assets/images/aquabox.png" alt="">
  <img style="display:none" id="cube" src="../assets/images/cube.png" alt="">
  <div class="scale-loader"></div>
</template>

<script>
import { fabric } from "fabric";
import Calculator from "/src/assets/lib/calculator.js"

const pdfHandler = require('pdfjs-dist/build/pdf')
const Base64Prefix = "data:application/pdf;base64,";
const pdfjs = require('pdfjs')

class Point {
  constructor(x, y) {
    this.x=x;
    this.y=y;
  }
}

export default {
  name: "drawing-canvas",
  props:{
    counters:{},
    pdfFile: null,
    targetPage: null,
    drawState: false,
    aquaboxScale: 1
  },
  data() {
    return {
      roof: null,
      roofPoints: [],
      lines: [],
      group: null,
      lineCounter: 0,
      drawingLine: false,
      drawingObject: {
        type: "roof",
        background: "",
        border: "",
      },
      canvas: null,
      x: 0,
      y: 0,
      lineStart: {
        x:0,
        y:0
      },
      lineIsValid: true,
      isDragging: false,
      lastPosX: null,
      lastPosY: null,
      clickScale: 1,
      isDrawing: false
    };
  },

  mounted() {
    const ref = this.$refs.can;
    this.canvas = new fabric.Canvas(ref);

     this.emitter.on("getSnapshot", () => {
      if(this.group)
        this.getCanvasSnapshot();
     });

    let canvasWidth = document.getElementsByClassName('canvas-container')[0].getClientRects()[0].width;
    let canvasHeight = document.getElementsByClassName('canvas-container')[0].getClientRects()[0].height;
    this.canvas.on('mouse:wheel', e => this.handleZoom(e));
    this.canvas.on('mouse:down', e => this.handleStartPanning(e))


    this.canvas.on('mouse:wheel', e => this.handleZoom(e));
    this.canvas.on('mouse:down', e => this.handleStartPanning(e))
    this.canvas.on('mouse:move', e => this.handlePanning(e))
    this.canvas.on('mouse:up', e => this.handleStopPanning(e))
    
    this.canvas.set('renderOnAddRemove', true);
    // this.canvas.set('preserveObjectStacking', true);

    // this.canvas.set('objectCaching', false);
    this.canvas.setDimensions({width:canvasWidth,height:canvasHeight});    
  },

  methods: {
    

    toggleDrawing() {
        if (this.drawingObject.type == "roof") {
        this.drawingObject.type = "";
        let can = this.canvas;
        this.lines.forEach(function (value, index, ar) {
            can.remove(value);
        });
        this.roofPoints = this.makeRoof(this.roofPoints);
        this.canvas.add(this.roofPoints);
        this.canvas.renderAll();

        //clear arrays
        this.roofPoints = [];
        this.lines = [];
        this.lineCounter = 0;

      } else {
        this.drawingObject.type = "roof"; // roof type
        this.drawingLine = false;
      }
    },

    handleDoubleClick(){
      if (this.drawingObject.type == "roof") {
        this.drawingObject.type = "";
        let can = this.canvas;

        this.lines.forEach(function (value, index, ar) {
          can.remove(value);
        });
        this.roofPoints.pop(this.roofPoints.length -1)
        this.lineCounter--;
        this.roofPoints = this.makeRoof(this.roofPoints);
        this.canvas.__eventListeners = {}

        this.canvas.add(this.roofPoints)
        this.group = new fabric.Group([],{
          height: this.roofPoints.height,
          width: this.roofPoints.width,
          left: this.roofPoints.left,
          top: this.roofPoints.top,
          hasControls: false,
        })
        let containerPoints = this.getPolygonPoints(this.roofPoints)
        let aquaboxGenerationData = this.generateAquabox(this.roofPoints, containerPoints);

       for(let item of aquaboxGenerationData.items){
         this.determineAquaboxType(item,aquaboxGenerationData.array);
       }
        
      this.group.add(this.roofPoints)

      for(let item of aquaboxGenerationData.items){
        this.group.add(item)
        this.canvas.remove(item)
       }
       
      this.canvas.remove(this.roofPoints)
      this.canvas.add(this.group); // Render polygon with aquabox


      this.canvas.off('mouse:down')
      this.canvas.on('mouse:down', e => this.handleTargetClick(e));
      let container = this.group.item(0);

      container.left = -(this.group.width /2);
      container.top = -(this.group.height /2);

      for(let i = 1; i < this.group._objects.length; i++){
        let item = this.group.item(i);
        item.left = -(this.group.width /2) + item.idPos[1]*(50 * this.clickScale);
        item.top= -(this.group.height /2) + item.idPos[0]*(50 * this.clickScale);
      }

      let calulations = new Calculator(this.counters).calculateAll();
      this.$emit('caluclations', calulations);
     

      // First bug (Fix rendering issues (Not showing aquabox elements on click))
      this.canvas.renderAll();

        //clear arrays
        this.roofPoints = [];
        this.lines = [];
        this.lineCounter = 0;
        this.drawingObject.type = "";
        this.$emit('stopDrawing');
        this.canvas.on('mouse:wheel', e => this.handleZoom(e));

      }
      this.isDrawing = false;
      
    },
    handleTargetClick(options){
      let pos = this.canvas.getPointer(options.e);
      for(let i = 1; i < this.group._objects.length; i++){
        let aquabox = this.group.item(i);
        
        if(this.checkIfClicked(aquabox,pos)){
          if(aquabox.aquabox_type.includes('A')){
              this.counters.aquabox[aquabox.aquabox_type]--;
              aquabox.set('aquabox_type',aquabox.aquabox_type.replace('A', 'C'));
              this.counters.cube[aquabox.aquabox_type]++;
              aquabox.item(0).set('opacity',0);
              aquabox.item(1).set('opacity',1);

            }
            else{
              this.counters.cube[aquabox.aquabox_type]--;
              aquabox.set('aquabox_type',aquabox.aquabox_type.replace('C', 'A'));
              this.counters.aquabox[aquabox.aquabox_type]++;
              aquabox.item(1).set('opacity',0);
              aquabox.item(0).set('opacity',1);

            }

            let calulations = new Calculator(this.counters).calculateAll();
            this.$emit('caluclations', calulations);
            
            
            this.canvas.renderAll();
        }
      }
    },

    handleMouseDown(options) {
      if (this.drawingObject.type == "roof") {
          if(this.lineIsValid){
            this.canvas.selection = false;
            if (!this.drawingLine) {
              this.setStartingPoint(options); // set x,y
              this.drawingLine = true;
            } else {
              this.setPoint(options);
            }
            this.roofPoints.push(new Point(this.x, this.y));
            var points = [this.x, this.y, this.x, this.y];

            let line = new fabric.Line(points, {
                strokeWidth: 3,
                selectable: false,
                stroke: 'blue',
              })
            
            this.lines.push(line);
            this.canvas.add(this.lines[this.lineCounter]);
            this.lineCounter++;

        }
      }
    },

    handleMouseUp(){
        this.canvas.selection = true;
        this.canvas.requestRenderAll();
    },

    handleMouseMove(options){
         if (this.lines[0] !== null && this.lines[0] !== undefined && this.drawingObject.type == "roof") {
            this.setPoint(options)

            this.lines[this.lineCounter - 1].set({
                x2: this.x,
                y2: this.y
            });
            

            this.canvas.renderAll();
            if(this.lineCounter > 1 )
                this.intersect()

            if(this.lineIsValid) 
                this.lines[this.lineCounter - 1].set({'stroke':'blue'});
            else
                this.lines[this.lineCounter - 1].set({'stroke':'red'});

           
        }
    },

    // Bug Reset zoom when drawing this.canvas.setZoom(1);
    handleZoom(opt){
      var delta = opt.e.deltaY;
      var zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    },

    handleStartPanning(opt){
      var evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      }
    },

    handlePanning(opt){
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.canvas.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.canvas.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    },

    handleStopPanning(opt){
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      if(this.isDragging){
        this.canvas.setViewportTransform(this.canvas.viewportTransform);
        this.isDragging = false;
        this.selection = true;
        
      }
    },

    setStartingPoint(options) {
        let pos = this.canvas.getPointer(options.e);

        this.x = pos.x;
        this.y = pos.y;

        this.lineStart.x = pos.x;
        this.lineStart.y = pos.y;
    },
    checkIfClicked(item, pos){
      let absoluteLeft = this.group.left + (1 + item.idPos[1]*(50*this.clickScale)) ;
      let absoluteTop = this.group.top + (1 + item.idPos[0]*(50* this.clickScale)) ;
      

      let points = {
        tl:{
          x: absoluteLeft,
          y: absoluteTop
        },
        tr:{
          x: absoluteLeft + 50* this.clickScale,
          y: absoluteTop
        },
        bl:{
          x: absoluteLeft,
          y: absoluteTop+50* this.clickScale
        }
      };
      let xCheck = points.tl.x < pos.x && points.tr.x > pos.x;
      let Ycheck = points.tl.y < pos.y && points.bl.y > pos.y;

      if(xCheck && Ycheck){
        return true;
        }

        
      else return false;

    },
    
    setPoint(options){
        let pos = this.canvas.getPointer(options.e);

        let tempX = pos.x;
        let tempY= pos.y;
        
        if (Math.abs(tempX - this.lines[this.lineCounter - 1].x1) > Math.abs(tempY - this.lines[this.lineCounter - 1].y1)) {
            // Horizontal direction
            this.x = tempX;
            this.y = this.lines[this.lineCounter - 1].y1;
        } else {
            // Vertical direction
            this.x = this.lines[this.lineCounter - 1].x1;
            this.y = tempY;
        }
    },

    makeRoof(roofPoints) {



        let diffX = Math.abs(roofPoints[0].x - roofPoints[this.lineCounter -1].x);
        let diffY = Math.abs(roofPoints[0].y - roofPoints[this.lineCounter -1].y);


        if(this.lineCounter%2 == 1){
          if(diffX > diffY){
            roofPoints.push(new Point(roofPoints[this.lineCounter-1].x,roofPoints[0].y))
            
          }
          else
            roofPoints.push(new Point(roofPoints[0].x,roofPoints[this.lineCounter-1].y))



          roofPoints.push(new Point(roofPoints[0].x,roofPoints[0].y))
        }
        else{
          if(diffX > diffY)
              roofPoints[this.lineCounter -1].y = roofPoints[0].y;
          else
              roofPoints[this.lineCounter -1].x = roofPoints[0].x;

          roofPoints.push(new Point(roofPoints[0].x,roofPoints[0].y))
        }

        var roof = new fabric.Polygon(roofPoints, {
        fill: 'rgba(0,0,0,0)',
        stroke:'#58c',
        id:'container',
        });
        

        return roof;
    },

    findTopPaddingForRoof(roofPoints) {
        var result = 999999;
        for (var f = 0; f < this.lineCounter; f++) {
            if (roofPoints[f].y < result) {
                result = roofPoints[f].y;
            }
        }
        return Math.abs(result);
    },

    findLeftPaddingForRoof(roofPoints) {
        var result = 999999;
        for (var i = 0; i < this.lineCounter; i++) {
            if (roofPoints[i].x < result) {
                result = roofPoints[i].x;
            }
        }
        return Math.abs(result);
    },

    intersect() {
        let currentLine = this.lines[this.lineCounter - 1];

        let valid = true;
        // console.log(currentLine)
        this.canvas.forEachObject(function(obj) {
            var checkPrevious = () =>{
               let checkx = obj.x2 != currentLine.x1;
               let checky = obj.y2 != currentLine.y1;
               
               return checkx && checky
            }

            var checkNext = () =>{
               let checkx = obj.x1 != currentLine.x2;
               let checky = obj.y1 != currentLine.y2;
               
               return checkx && checky
            }
            if(obj != currentLine && checkPrevious() && checkNext()){

                if(currentLine.intersectsWithObject(obj,false,true)){
                    valid = false
                }
                else
                    valid = true

            }
        });
        this.lineIsValid = valid
    },

    generateAquabox(container, containerPoints){
        let aquaboxSide = 50* this.clickScale;

        let numbInWidth = Math.floor(container.width/(aquaboxSide));
        let numbInHeight = Math.floor(container.height/(aquaboxSide));

        let leftPreset = container.left +1; 
        let topPreset =  container.top +1; 


        let aquaboxArray = [];
        let idArray = [];
        

        for(let i = 0; i< numbInHeight; i++){
          for(let j = 0; j < numbInWidth; j++){
            

              let aquabox = this.generateSingleAquabox(topPreset + i*(aquaboxSide), leftPreset + j*(aquaboxSide),aquaboxSide, [i,j], 'A')

              let points = aquabox.getCoords();
              let inside = true;
              for(let point of points){
                if(!this.inside(point, containerPoints))
                  inside = false;
              }
              if(!inside)
                  this.canvas.remove(aquabox)
              else{
                aquaboxArray.push(aquabox)
                idArray.push(aquabox.idPos)
              }
          }
        }

       
        return {items: aquaboxArray , array: idArray};
    },

    generateSingleAquabox(top,left,length,position,type){
      console.log(length)
        let group= new fabric.Group([], {
            width: length,
            height: length,
            left: left,
            top: top,
            idPos: position,
            evented: true,
            aquabox_type:type,
          });

          let blue = new fabric.Image(document.getElementById('aquabox'), {
              width: 50,
              height: 50,
              originX: 'center',
              originY: 'center',
              scaleX: this.clickScale,
              scaleY: this.clickScale,

          });

          let red = new fabric.Image(document.getElementById('cube'), {
              width: 50,
              height: 50,
              originX: 'center',
              originY: 'center',
              opacity: 0,
               scaleX: this.clickScale,
              scaleY: this.clickScale,
          });
          group.add(blue); 
          group.add(red); 
          

          return group
          
    },

  determineAquaboxType(aquabox,array){
    let typeNumber = 4;
    
    let adjacents = [
      [aquabox.idPos[0]+1,aquabox.idPos[1]],
      [aquabox.idPos[0]-1,aquabox.idPos[1]],
      [aquabox.idPos[0],aquabox.idPos[1]+1],
      [aquabox.idPos[0],aquabox.idPos[1]-1],
    ]
    for( let i = 0 ; i < adjacents.length; i++){
      if(array.some(a => adjacents[i].every((v,j) => v === a[j])))
        typeNumber--;
    }
    let type = `A${typeNumber}`;
    this.counters.aquabox[type] = this.counters.aquabox[type]+1;
    aquabox.aquabox_type = type
  },

  async scaleDownAquabox(){

    // this is retarded, but ok
    while(this.group._objects.length > 1){
      for(let i = 1; i < this.group._objects.length; i++){
         this.group.remove(this.group.item(i))
      }
    }

    let polygonContainer;

    this.group.item(0).clone(clone => polygonContainer = clone);

    polygonContainer.left = this.group.left;
    polygonContainer.top = this.group.top;

    this.canvas.remove(this.group); 
    
    let newGroup = new fabric.Group([],{
      height: this.group.height,
          width: this.group.width,
          left: this.group.left,
          top: this.group.top,
          hasControls: false,
        })

    this.canvas.add(polygonContainer)
    
    let containerPoints = this.getPolygonPoints(polygonContainer)
    let aquaboxGenerationData = this.generateAquabox(polygonContainer, containerPoints);

    for(let item of aquaboxGenerationData.items){
      this.determineAquaboxType(item,aquaboxGenerationData.array);
    }
        
    newGroup.add(polygonContainer)

    for(let item of aquaboxGenerationData.items){
      newGroup.add(item)
      this.canvas.remove(item)
    }
    this.canvas.remove(polygonContainer)
    this.group = newGroup;
    this.canvas.add(this.group); 
    // Render polygon with aquabox
    let container = this.group.item(0);

    container.left = -(this.group.width /2);
    container.top = -(this.group.height /2);

    for(let i = 1; i < this.group._objects.length; i++){
      let item = this.group.item(i);
      item.left = -(this.group.width /2) + item.idPos[1]*(50 * this.clickScale);
      item.top= -(this.group.height /2) + item.idPos[0]*(50 * this.clickScale);
    }
      // First bug (Fix rendering issues (Not showing aquabox elements on click))
    this.canvas.renderAll();
    document.querySelector('.scale-loader').classList.remove('open');

  },
    
  inside(point, vs) {
    var x = point.x, y = point.y;
    
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
  },
  getPolygonPoints(polygon){

    return polygon.get('points').map(function(p) {
      return [p.x,p.y];
    });
  },

  readBlob(blob) {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve(reader.result));
          reader.addEventListener('error', reject)
          reader.readAsDataURL(blob);
      })
  },

   getPdfHandler() {
      return pdfHandler;
  },

  async printPDF(pdfData, pageNumber) {
      const pdfjsLib = this.getPdfHandler();
      const canvas = document.getElementById('bg');
      pdfData = pdfData instanceof Blob ? await this.readBlob(pdfData) : pdfData;
      const data = atob(pdfData.startsWith(Base64Prefix) ? pdfData.substring(Base64Prefix.length) : pdfData);
      // Using DocumentInitParameters object to load binary data.
      const loadingTask = pdfjsLib.getDocument({ data });
      this.$emit('loadedPdf');
      return loadingTask.promise
          .then((pdf) => {
              return pdf.getPage(pageNumber)
                  .then((page) => {
                      //  retina scaling
                      const viewport = page.getViewport({ scale: window.devicePixelRatio });
                      const context = canvas.getContext('2d');

                      canvas.height = viewport.height
                      canvas.width = viewport.width;

                      // Render PDF page into canvas context
                      const renderContext = {
                          canvasContext: context,
                          viewport: viewport
                      };
                      const renderTask = page.render(renderContext);
                      return renderTask.promise.then(() => canvas);
                  });
          });
  },

  async pdfToImage(pdfData,pageNumber) {
    console.log(pdfData)
      return await this.printPDF(pdfData,pageNumber)
          .then(c => {
              let img = new fabric.Image(c, {
                  id: 'background',
              })
              let can = this.canvas;
              can.setBackgroundImage(null, can.renderAll.bind(can));
              can.setBackgroundImage(img);
              can.renderAll();
          });
  },

  async importPdf(pageNumber){
        await this.pdfToImage(this.pdfFile, pageNumber);
  },
  resetPolygon(){
    this.canvas.remove(this.group)
    this.group = null;
    this.drawingObject.type="roof";
    this.x = 0,
    this.y= 0,
    this.lineStart= {
        x:0,
        y:0
    };
    this.drawingLine = false;

    this.canvas.off('mouse:up');
    this.canvas.off('mouse:down');
    this.canvas.off('mouse:move');
    this.canvas.off('mouse:dblclick');


    this.canvas.on('mouse:wheel', e => this.handleZoom(e));
    this.canvas.on('mouse:down', e => this.handleStartPanning(e))
    this.canvas.on('mouse:move', e => this.handlePanning(e))
    this.canvas.on('mouse:up', e => this.handleStopPanning(e))
    this.$emit('clearCalculations')
  },

  getCanvasSnapshot(){

    let vpt = this.canvas.viewportTransform
    vpt[0] = 1;
    vpt[1] = 0;
    vpt[2] = 0;
    vpt[3] = 1;
    vpt[4] = 0;
    vpt[5] = 0;

    this.canvas.setViewportTransform (vpt);
    this.canvas.renderAll();


    let snapshot = this.canvas.toDataURL({
      format: 'jpeg',
      top: this.group.top - 50,
      left: this.group.left - 50,
      width: this.group.width + 100,
      height: this.group.height + 100,
      });

      this.emitter.emit('sendSnapshot', snapshot)
  },

  },
   watch: {
      targetPage: function (val) {
       if(val){
        this.importPdf(val)
       }
      },
      isDrawing: function (state){
        if(state){
          this.canvas.off('mouse:up');
          this.canvas.off('mouse:move');
          this.canvas.off('mouse:down');

          this.canvas.on('mouse:wheel', e => this.handleZoom(e));
          this.canvas.on('mouse:down', e => this.handleMouseDown(e))
          this.canvas.on('mouse:up', this.handleMouseUp())
          this.canvas.on('mouse:move', e => this.handleMouseMove(e))
          this.canvas.on('mouse:dblclick', e => this.handleDoubleClick(e))
        }
        else{
          this.canvas.off('mouse:up');
          this.canvas.off('mouse:move');
          this.canvas.off('mouse:dblclick');


          this.canvas.on('mouse:wheel', e => this.handleZoom(e));
          this.canvas.on('mouse:down', e => this.handleStartPanning(e))
          this.canvas.on('mouse:move', e => this.handlePanning(e))
          this.canvas.on('mouse:up', e => this.handleStopPanning(e))
          
        }
      },
      aquaboxScale: function(val){
        this.clickScale = val;

        if(this.group)
          this.scaleDownAquabox(val)


        
      }
     
    }
};
</script>