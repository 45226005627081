<template>
  <div v-if="numPages > 0" class="overlay-wrapper">
    <div class="pdf-picker-container">
      <VuePdf :key="page" :src="pdfSrc" :page="page" />
      <div id="buttons" class="page-button">
        <a class="item back-pdf-page" @click="page > 1 ? page-- : 1">
          <span>Back</span>
        </a>
        <a class="active item">
          <span>{{ page }} / {{ numPages ? numPages : '∞' }}</span>
        </a>
        <a class="item forward-pdf-page" @click="page < numPages ? page++ : 1">
          <span>Forward</span>
        </a>
      </div>
      <button class="item select-button" @click="this.$emit('selectPage', page); numPages = 0;">
        <span>UPLOAD PDF</span>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { VuePdf, createLoadingTask } from 'vue3-pdfjs/esm';

export default defineComponent({
  name: 'pdf-picker',
  components: { VuePdf },

  props: {
    pdfSrc: ""
  },

  data() {
    return {
      numPages: 0,
      page: 1,
    }
  },

  watch: {
    pdfSrc: async function (val) {
      if (val != '') {
        const loadingTask = createLoadingTask(val)

        const pdf = await loadingTask.promise;
        this.numPages = pdf._pdfInfo.numPages;

        // Use nextTick to ensure the DOM is updated
        await nextTick();

      }
    },

  }

});
</script>

<style>
.page-button .item {
  font-family: 'helvetica';
  padding: 10px;
  color: white;
  background-color: #f5842b;
}

.page-button .item:hover {
  cursor: pointer;
}

.page-button {
  padding: 10px;
  text-align: center;
}

.page-button span{
    position: relative;
    top: 2px;
}

.active span {
  position: relative;
  top: 2px;
}

.select-button {
  text-transform: uppercase;
  background: #f5842b;
  color: #fff;
  font-size: 15px;
  text-align: center;
  border: 0;
  height: 40px;
  width: 140px;
  line-height: 10px;
  cursor: pointer;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'helvetica';
  top: 60px;
}

.select-button span{
    position: relative;
    top: 1px;
}

.select-button:hover {
  background-color: #16478b;
}

.back-pdf-page:hover {
  background-color: #16478b;
}

.forward-pdf-page:hover {
  background-color: #16478b;
}
</style>